@import (reference) '../../styles/colors.less';
@import '../../../../../../node_modules/react-day-picker/lib/style.css';

.day-picker {
	.DayPicker {
		font-size: 12px;
		display: block;

		&:not(.DayPicker--interactionDisabled)
			.DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
			background-color: transparent;
		}

		&:focus {
			outline: none;
		}

		&-wrapper {
			padding: 0;

			&:focus {
				outline: none;
			}
		}

		&-Month {
			margin-top: 22px;
		}

		/* Table Column header */
		&-Weekdays {
			margin-top: 22px;

			&:focus {
				outline: none;
			}
		}

		&-Weekday {
			padding: 0 15px;
			text-transform: uppercase;
			color: @brandSecondary;
			font-size: 11px;

			&:focus {
				outline: none;
			}
		}

		&-Day {
			padding: 8px 10px;

			.clearFormatting() {
				color: @titles;
				background-color: transparent;
				font-weight: normal;

				&:not(.DayPicker-Day--today) {
					color: @titles;
				}

				&:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
					background-color: transparent;
					color: @titles;
				}

				&:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
					background-color: transparent;
				}
			}

			.clearFormatting();

			&:hover {
				.clearFormatting();
			}

			&--selected {
				.clearFormatting();
			}

			&--today {
				.clearFormatting();
				position: relative;
			}

			&.DayPicker-Day--outside {
				color: #8b9898;
			}

			&.DayPicker-Day--disabled {
				color: #8b9898;
				pointer-events: none;
			}
		}

		&-NavBar {
			display: flex;
			align-items: center;
			min-height: 30px;
			padding: 0 17px;

			&-month {
				flex-grow: 1;
				text-align: center;
				font-size: 14px;
				color: #8e8e8e;
				width: calc(100% - 40px);
			}

			&-next-button {
				padding: 5px;
				width: 20px;

				svg {
					transform: rotate(180deg);
				}

				&.hide {
					visibility: hidden;
				}
			}

			&-prev-button {
				padding: 5px;
				width: 20px;

				&.hide {
					visibility: hidden;
				}
			}
		}
	}

	&-day {
		width: 24px;
		height: 24px;
		justify-content: center;
		align-items: center;
		display: inline-flex;
		border-radius: 50%;
		overflow: hidden;
		flex-basis: 0;

		&.day-picker-day--today {
			border: 1px @brandSecondary solid;
		}

		&.day-picker-day--selected:not(.day-picker-day--disabled) {
			background-color: @brandSecondary;
			color: #fff;
		}

		&:not(.day-picker-day--selected):hover {
			background-color: @nameCircles;
		}

		&.day-picker-day--selected.day-picker-day--disabled {
			background-color: @destructive;
			color: #fff;
		}
	}
}

.day-picker-plugin {
	.DayPicker {
		&-Day {
			padding: 5px;
			margin-top: 10px;
			margin-left: 10px;
			margin-right: 10px;
		}

		&-NavBar {
			padding: 0 5px;
		}

		&-Month {
			margin-top: 10px;
			margin-bottom: 5px;
		}

		&-Weekday {
			padding: 10px;
		}
	}
}
